.section{
    max-width: 600px;
    padding: 5em 1.5em;
    background-color: rgba(255,255,255, 0.7);
    margin: 0 0.5em 1em;
    letter-spacing: 1px;
    
}

.sectionTitle {
    font-size: 2.5em;
    text-align: center;
    line-height: 1.1;
    margin: 1em;
}

.message {
    font-size: 1.1em;
    margin: 0 0 2em;
}

.articleTitle {
    margin-bottom: 0.25em;
}

.list {
    padding-left: 2em;
    list-style: none;
}

.listItem {
    margin-bottom: 1em;
}

@media (min-width: 600px) {
    .section {
        max-width: 600px;
        margin: 0 auto 1em;

    }
}