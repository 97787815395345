.section {
    margin-top: 3em;
    min-height: 95vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: rgba(70, 69, 69, 0.6);
}

.container {
    max-width: 800px;
    margin: 2em auto;
    padding: 1em 0.5em 0;
    border-radius: 6px;

}

.locationsTitle {
    padding: 0 1em 0.5em;
    color: var(--color-highlight2);
}

.locationsText {
    color: var(--color-secondary);
    margin: 1em;
    font-size: 1.2em;
}

.locationsLink {
    color: var(--color-highlight2);
}

.bodyHeader {
    font-weight: bolder;
}

.bodyText {
    padding: 0 2em 1em;
}

.typography {
    display: flex;
    align-items: center;
}

.map {
    max-width: 60rem;
    margin: 1em auto;
    border: 2px solid var(--color-highlight2);
    height: auto;
}

.mapImage {
    width: 100%;
}

.locationLink {
    color: var(--color-highlight2);
    text-decoration: underline;
}

@media (min-width: 500px) {
    .section {
        padding: 3em;
    }

    .container {
        padding: 2em;
    }
}