.section {
    max-width: 100vw;
    margin-top: 0;
    padding: 3em 0.5em;
    display: flex;
    flex-direction: column;
    align-items: center;

}

.container {
    margin: 0 auto;
    max-width: 900px;
}

.sectionTitle {
    font-family: "Great Vibes";
    font-size: 2.5em;
    padding: 1em;
    text-shadow: 2px 4px 3px #5d5d5d;
    color: var(--color-secondary);
    letter-spacing: 3px;
}

.title {
    margin: 2em 0;
}

.photo {
    width: 100%;
    height: auto;
    margin: 0 0 0.5em;
}
