

.header {
    width: 100%;
    position: fixed;
    top: 0;
    z-index: 950;
    background: linear-gradient(159deg, rgb(143, 142, 142) 10%, rgb(255, 255, 255) 25%, rgb(235, 234, 234) 52%, rgb(143, 142, 142) 89%);
    height: 75px;
    display: flex;
}

.footer{
    height: 150px;
    width: 100%;
    background-color: var(--color-primary);
    background: linear-gradient(159deg, rgb(70, 70, 70) 10%, rgb(44, 44, 44) 25%, rgb(31, 30, 30) 52%, rgb(69, 69, 69) 89%);
    color: var(--color-secondary);
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    margin-top: auto;
    letter-spacing: 1px;
}

.copyright {
    font-size: 1.8rem;
    padding-top: 2em;
}

.creator {
    font-size: 1.4rem;
    padding: 0 1em;
}

.portfolioLink{
    letter-spacing: 2px;
    text-underline-offset: 0.4em;
}

.portfolioLink:focus{
    text-decoration: underline;
    color: var(--clr-highlight2);
}

.pageTitle {
    font-family: "Great Vibes", sans-serif;
    font-size: 1.25em;
    font-weight: 700;
    text-align: center;
    text-transform: capitalize;
    letter-spacing: 6px;
    padding: 1em 0 0.5em;
}

.navContent {
    width: 95%;
    margin: 0 auto;
    padding: 0 1em;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.navTitle {
    user-select: none;
}


.nav {
    position: fixed;
    top: 75px;
    bottom: 0;
    right: 0;
    left: 0;
    z-index: 900;
    background: linear-gradient(139deg, rgba(17,17,17,1) 38%, rgba(98,98,98,1) 52%, rgba(17,17,17,1) 64%);
    transform: translateY(-140%);
    opacity: 0;
    transition: opacity 500ms ease;
}

.navTitleLink {
    display: flex;
    align-items: center;
    width: 100%;
}


.sticky .header {
    border-bottom: 3px solid var(--color-highlight);
    /* -webkit-box-shadow: 6px 19px 17px -15px rgba(38, 38, 38, 0.67);
    -moz-box-shadow: 6px 19px 17px -15px rgba(38, 38, 38, 0.67);
    box-shadow: 6px 19px 17px -15px rgba(38, 38, 38, 0.67); */
}


.navList {
    list-style: none;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    text-transform: uppercase;
}

.navListItem {
    padding: 0.25em 1em;
}

.navListItem:last-of-type {
    border-bottom: none;
}

.navLink {
    color: var(--color-secondary);
    text-decoration: none;
    font-size: 1.65rem;
    font-weight: 700;
    width: 100%;
    padding: 0.25em 1em;
}
.navLink div{
    padding-left: 1em;
}

.sticky .nav-link {
    color: var(--color-highlight2);
  }


.active {
    color: var(--clr-highlight2);
  }


.menuIcon {
    width: 2rem;
    height: 20px;
    cursor: pointer;
    background: transparent;
    padding: 0;
    position: relative;
    right: 0;
    z-index: 1000;
}

.navOpen .menuIcon {
    right: 0;
}

.hamburger {
    display: block;
    position: absolute;
    top: 50%;
    right: 0;

}


.hamburger,
.hamburger::after,
.hamburger::before {
    background-color: var(--color-dark);
    height: 4px;
    width: 2rem;
    border-radius: .5em;
    transition: transform 250ms ease-in-out;
}

.hamburger::after,
.hamburger::before {
    content: "";
    position: absolute;
}

.sticky .hamburger,
.sticky .hamburger::after,
.sticky .hamburger::before {
    background-color: var(--color-highlight);
}


/* .navOpen .hamburger,
.navOpen .hamburger::after,
.navOpen .hamburger::before {
    background-color: var(--color-highlight);
} */

.hamburger::after {
    top: 8px;
}

.hamburger::before {
    bottom: 8px;
}

.navOpen .nav {
    transform: translateY(0);
    opacity: 1;
    /* box-shadow: 2px 2px 6px var(--color-secondary); */
}

.navOpen .hamburger {
    transform: rotate(135deg)
}

.navOpen .hamburger::before {
    opacity: 0;
}

.navOpen .hamburger::after {
    transform: rotate(90deg) translateX(-8px)
}

.navOpen .navList {
    padding: 0;
    margin: 0;
}

.navLink:hover,
.navOpen .navLink:active {
    color: var(--color-highlight2);
}

/* ============ MEDIA QUERIES ============ */
@media (min-width: 1100px) {
    .navContent {
        max-width: 1100px;
        margin: 0 auto;
    }
}

@media (min-width: 800px) {

    .header {
        align-items: center;
    }

    .menuIcon {
        display: none;
    }

    .nav {
        background: transparent;
        position: static;
        transform: translateX(0%);
        transition: 300ms ease-in-out;
        padding: 0 3em 0 1em;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
        opacity: 1;
    }
    
    .navTitle {
        font-size: 1.3em;
        letter-spacing: 3px;
    }

    .navList {
        flex-direction: row;
        justify-content: space-around;
        align-items: center;
        margin: 0;
    }

    .navListItem {
        border-bottom: none;
    }

    .navLink {
        color: var(--color-primary);
        text-decoration: none;
        font-weight: 700;
        padding: 0 0.2em;

        text-decoration: none;
    }

    .navLink:hover {
        text-decoration: 3px double var(--color-highlight2);
    }

}