@import url('https://fonts.googleapis.com/css2?family=Great+Vibes&family=Pragati+Narrow:wght@400;700&display=swap');
:root {
  --color-primary: #434344;
  --color-secondary: #f4f4f4;
  --color-dark: #535355;
  --color-highlight: rgba(234, 2, 234, 1);
  --color-highlight2: #25eddc;
  
  font-family: "Pragati Narrow", sans-serif;
  font-size: 62.5%;
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

html,
body {
  max-width: 100vw;
  overflow-x: hidden;
  font-size: 1.6em;
  letter-spacing: 0.1rem;
}

body {
  min-height: 100vh;
  background-color: var(--color-secondary);
  /* background: url(https://res.cloudinary.com/abeonweb/image/upload/v1682182063/blackslab_n2blsh.jpg); */
  background: url(images/blackslab.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-blend-mode: overlay;
  background-position: 20%;
  display: flex;
  flex-direction: column;
}

a {
  color: inherit;
  text-decoration: none;
}

#main {
  min-height: 100vh;
}
