.section {
    padding: 3em 0;
    width: 100vw;
    background-color: rgba(27, 28, 28, 0.5);
}

.container {
    max-width: 600px;
    margin: 0;
    padding: 1em;
    border-radius: 6px;
}

.sectionTitle {
    font-family: "Great Vibes";
    font-size: 2.5em;
    padding: 1em;
    text-shadow: 2px 4px 3px #5d5d5d;
    color: var(--color-secondary);
    letter-spacing: 3px;
}

.text {
    color: var(--color-secondary);
    font-size: 1.2em;
    text-shadow: 4px 4px 16px rgb(13, 13, 13);
    line-height: 1.6;
    padding-bottom: 0.85em;
}

@media (min-width: 500px){
    .section {
        padding: 3em;
    }

    .container {
        margin: 0 auto;
    }
}