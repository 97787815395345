.splashImageContainer {
  min-height: 100vh;
  width: 100vw;
  padding: 0 2em 2em;
  display: flex;
  background: transparent;
  /* background: url(../images/blackslab.jpg); */
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  user-select: none;

}

.logo {
  width: 250px;
  height: auto;
}

@media (min-width: 400px) {
  .logo {
    width: 350px;
  }
}